<template>
  <div class="v-dialog">
    <modal
      :name="name"
      height="auto"
      @before-open="onBeforeOpened"
      @closed="onAfterClosed"
    >
      <div class="v-dialog__container">
        <div class="v-dialog__header">
          <button class="v-dialog__button" @click="onClose">
            <svg
              width="20"
              height="20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="v-dialog__icon"
            >
              <path
                d="M20 2.014L17.986 0 10 7.986 2.014 0 0 2.014 7.986 10 0 17.986 2.014 20 10 12.014 17.986 20 20 17.986 12.014 10 20 2.014z"
              />
            </svg>
          </button>
        </div>
        <div class="v-dialog__body">
          <slot></slot>
        </div>
      </div>
      <div class="v-dialog__helper" @click="onClose"></div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "v-dialog",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onClose() {
      this.$modal.hide(this.name);
    },
    onBeforeOpened() {
      const body = document.body;
      const width = window.innerWidth - body.clientWidth;
      body.classList.add("v-dialog__body");
      body.style.paddingRight = `${width}px`;
    },
    onAfterClosed() {
      const body = document.body;
      document.body.classList.remove("v-dialog__body");
      body.style.paddingRight = `0px`;
    },
  },
  destroyed() {
    this.onAfterClosed();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.v-dialog {
  position: absolute;
  left: -9999em;

  &__container {
    background: $white;
    position: relative;
    border-radius: $radius-md;
    padding: $space-56;
    box-sizing: border-box;
    max-width: 100%;
    box-shadow: $shadow-lg;
    overflow: hidden;
    margin: $space-56 0;
    pointer-events: all;
  }

  &__body {
    overflow: hidden;
  }

  &__helper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    pointer-events: all;
  }

  &__button {
    appearance: none;
    position: absolute;
    right: $space-16;
    top: $space-16;
    background: transparent;
    border: 0;
    width: $space-40;
    height: $space-40;
    padding: $space-40 / 4;
    cursor: pointer;
  }

  &__icon {
    fill: $gray-500;
    transition: fill $trans-anim;

    &:hover {
      fill: $gray-900;
    }
  }
}

.vm {
  &--modal {
    border-radius: 0;
    background: transparent !important;
    left: 0 !important;
    padding: 0;
    max-width: 640px !important;
    width: 100%;
    box-shadow: none !important;
    overflow: hidden;
    position: static;
    margin: auto;
    pointer-events: none;
  }

  &--overlay {
    display: none;
  }

  &--container {
    overflow: auto;
    background: rgba($gray-900, 0.56);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
